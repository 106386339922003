import React from 'react';
import '../sass/App.scss';
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
} from "react-router-dom";
import Home from './../pages/home/Home';
import DetailPlantacle from '../pages/detail-project/DetailPlantacle';
import DetailMusicTerra from '../pages/detail-project/DetailMusicTerra';
import DetailDivineApocalypse from '../pages/detail-project/DetailDivineApocalypse';
import DetailBlownAway from '../pages/detail-project/DetailBlownAway';
import DetailPhaseRunner from '../pages/detail-project/DetailPhaseRunner';
import DetailStrakzat from '../pages/detail-project/DetailStrakzat';
import DetailSkynerium from '../pages/detail-project/DetailSkynerium';

class App extends React.Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/music-terra" component={DetailMusicTerra} />
                  <Route path="/divine-apocalypse" component={DetailDivineApocalypse} />
                  <Route path="/blown-away" component={DetailBlownAway} />
                  <Route path="/phase-runner" component={DetailPhaseRunner} />
                  <Route path="/skynerium" component={DetailSkynerium} />
                  {/* <Route path="/plantacle" component={DetailPlantacle} />
                  <Route path="/strakzat" component={DetailStrakzat} /> */}
                  {/* <Route path="/banzai" component={DetailBanzai} /> */}
                  {/* <Route path="/ile-erasmus" component={DetailILE} /> */}
                </Switch>
            </HashRouter>
        );
    }
}

export default App;

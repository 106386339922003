import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageNumber from './../../../components/atoms/PageNumber';

const Intro = () => {
    return(
        <section className="intro" id="section2">
            <Container style={{ maxWidth: '2000px' }}>
                <Row>
                    <Col>
                    <div className="intro__pagenumber--wrapper">
                    <PageNumber title="about me" count="01"> </PageNumber>
                    </div>
                    <div className="intro__image-wrapper">
                        <div className="intro__frame">
                        </div>
                    </div>
                    <div className="intro__text-wrapper">

                    <div className="intro__content-wrapper">
                        <p className="intro__title"> Hi, </p>
                        <p className="intro__text"> My name is Hayen. I am currently doing my Master's in Music Design. I am passionate about enhancing storytelling in games through music and implementation to create deeply immersive experiences.  </p>
                        {/* <div className="intro__wave">  </div> */}
                    </div>
                    </div>

                    </Col>
                </Row>
            </Container>
        </section>
    )
};

export default Intro;

import React from 'react';
import { Container } from 'react-bootstrap';
import SoundCloudBlownAway from './sections/BlownAway/SoundCloudBlownAway';
import YouTubeBlownAway from './sections/BlownAway/YoutubeBlownAway';

// components
import Intro from './sections/Intro';
import Role from './sections/Role';


class DetailBlownAway extends React.Component {
  render() {
    return (
      <Container fluid>
          <Intro
            title="Blown Away"
            text="Blown away is a singleplayer explorer game filled with puzzles, obstacles and hidden locations. The game takes place in a civilization called Yhorba influenced by bubbles. One day the machine that provides the protective bubble around the island breaks down. This caused the island to fall down and crash on the abandoned planet underneath. Your goal is to repair the machine to restore the protective bubble. To do so you must find the mechanical parts that are scattered around the abandoned planet. By solving puzzles, overcoming obstacles and explore hidden areas, Mylo can locate these mechanical parts."
            countTitle="b.a"
            count="0.3.1"
            id="blownaway1"
          >
          </Intro>
          <Role
            role="Composer, Sound designer"
            responsibility="The main character uses the Hurdy Gurdy instrument as his most trusted tool. The sound produced by this instrument resembles mechanically rusted strings. A wide variety of sound effects and foley sounds were created, as well as the soundtracks for this game."
            build="Ableton, Synthesizers, Orchestral VST's"
            buildTitle="Tools"
            project="Blown Away"
          />
            <SoundCloudBlownAway
             title={ "Music"}
              text={"Soundtracks were composed for the start screen, forest, town, and trailers, predominantly using orchestral instruments combined with synthesizers. The instrumental choices were made to match the fantasy medieval-themed art style. Initially, several variations of the town music were created. Consequently, a 90% orchestral setup was chosen, with the remaining 10% incorporating synthesizers."}
              url={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1540792177&color=%234a6654&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"}
              className={"blownaway-soundcloud"}
            />
            <SoundCloudBlownAway
              title={"Sound design"}
              text={""}
              url={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1540730530&color=%234a6654&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"}
              className={"blownaway-soundcloud2"}
            />

        <YouTubeBlownAway
          title={"BlownAway Trailer"}
          text={"For the trailer music, a variation on the original forest theme was created, as it was the most adventurous sounding theme of the game. The heavier percussions were removed, and the chords and melodies were modified to better accompany the trailer's visual aspect."}
          url={"https://www.youtube.com/embed/-72PG6JoZtc"}
          />
      </Container>
    )
  }
}

export default DetailBlownAway;

import React, { useState } from 'react';
import Label from '../atoms/Label';
import Button from './../atoms/Button';

const ProjectText = (props) => {
    const [labelText, setLabelText] = useState([]);
    React.useEffect(() => {
        setLabelText(props.labels);

        console.log(labelText);
    }, [labelText, props.labelText, props.labels]);

    return(
      <div className="project__text-wrapper">
          <h2 className="project__title--desktop"> {props.title} </h2>
          <div className="project__label-wrapper">
            {labelText.map((label, index) => (
                    <Label labelText={label} />
                ))}
          </div>
          <h2 className="project__text"> {props.text} </h2>
          <div className="project__button-wrapper">
              <Button buttonLink={props.buttonLink} title={props.title} />
          </div>
      </div>
    )
};

export default ProjectText;

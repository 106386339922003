import React from 'react';
import { Container } from 'react-bootstrap';
// components
import Intro from './sections/Intro';
import Role from './sections/Role';
import YouTubeBlownAway from './sections/BlownAway/YoutubeBlownAway';


class DetailStrakzat extends React.Component {
  render() {
    return (
      <Container fluid>
          <Intro
           title="Strakzat"
           text="Strakzat is a digital agency based in Rotterdam. They research, design and build digital products and interactive experiences. They give their digital services from the start to the launch of a project."
           countTitle="strakzat"
           count="0.6.1"
           githubLink="https://strakzat.com/"
           linkText="View the website"
           id="strakzat1"
           >
          </Intro>
          <Role
            role="Front-end developer, Designer"
            responsibility="During my internship at Strakzat, I was tasked with building their website. This challenge brought with it a couple of objectives and tasks, which, in the end, I could add to my list of achievements. The components were built in React and Prismic CMS for managing the content. For the website, I made animations and filter-buttons and collected information using APIs. After my internship ended, I continued to maintain my work at Strakzat as an employee."
            build="React.js, Sass"
            buildTitle="Tools"
            project="Plantacle"
          />
          <YouTubeBlownAway
          title={"Strakzat website video"}
          url={"https://www.youtube.com/embed/Xu2X-WYlYFo"}
          />
      </Container>
    )
  }
}

export default DetailStrakzat;

import React from 'react';
import styled from 'styled-components';
import imgPlantacle from '../../images/mockup-plantacle-desktop.png';
import imgILE from '../../images/macbook_mockup--ILE.png';
import imgBlownAway from '../../images/mockup-blownaway-desktop.png';
import imgMusicTerra from '../../images/mockup-musicterra-desktop.png';
import imgPhaseRunner from '../../images/mockup-phaserunner-desktop.png';
import imgStrakzat from '../../images/mockup-strakzat-desktop.png';
import imgDivineApocalypse from '../../images/mockup-divineapocalypse-desktop.png';
import imgBloodyHell from '../../images/mockup-bloody-desktop.png';
import imgSkynerium from '../../images/mockup-skynerium-desktop.png';
import imgBlownAwayMobile from '../../images/mockup-blownaway-mobile.png';
import imgMusicTerraMobile from '../../images/mockup-musicterra-mobile.png';
import imgPlantacleMobile from '../../images/mockup-plantacle-mobile.png';
import imgPhaseRunnerMobile from '../../images/mockup-phaserunner-mobile.png';
import imgStrakzatMobile from '../../images/mockup-strakzat-mobile.png';
import imgDivineApocalypseMobile from '../../images/mockup-divineapocalypse-mobile.png';
import imgBloodyHellMobile from '../../images/mockup-bloody-mobile.png';
import imgSkyneriumMobile from '../../images/mockup-skynerium-mobile.png';

const MockupImage = styled.div`
    background-image: url(${props => props.title === 'Plantacle' ? imgPlantacleMobile : props.title === 'Blown Away' ? imgBlownAwayMobile : props.title === 'Music Terra' ? imgMusicTerraMobile : props.title === 'Phase Runner' ? imgPhaseRunnerMobile : props.title === 'Strakzat' ? imgStrakzatMobile : props.title === 'Divine Apocalypse' ? imgDivineApocalypseMobile : props.title === 'Bloody Hell II' ? imgBloodyHellMobile :  props.title === 'Skynerium' ? imgSkyneriumMobile : null });
    background-size: ${props => props.title === 'Divine Apocalypse' ? '350px 210px' : '350px 210px'};
    background-repeat: no-repeat;
    width: 100%;
    height: 189px;
    margin: 0 auto;
    background-position: center;

    @media (min-width: 500px) {
        background-size: ${props => props.title === 'Divine Apocalypse' || props.title === "Bloody Hell II" || props.title === "Skynerium"? '500px 349px' : '500px 289px'};
        background-image: url(${props => props.title === 'Plantacle' ? imgPlantacle : props.title === 'Blown Away' ? imgBlownAway : props.title === 'Music Terra' ? imgMusicTerra : props.title === 'Phase Runner' ? imgPhaseRunner : props.title === 'Strakzat' ? imgStrakzat : props.title === 'Divine Apocalypse' ? imgDivineApocalypse : props.title === 'Bloody Hell II' ? imgBloodyHell : props.title === 'Skynerium' ? imgSkynerium : null });
        width: 400px;
        height: 289px;
    }

    @media (min-width: 768px) {
        background-size: 700px 440px;
        background-size: ${props => props.title === 'Divine Apocalypse' || props.title === "Bloody Hell II" || props.title === "Skynerium" ? '700px 440px' : '700px 400px'};
        width: 498px;
        height: 389px;
    }

    @media (min-width: 1024px) {
        // background-size: 700px 420px;
        // width: 598px;
        width: 100%;
        height: 500px;
    }

    @media (min-width: 1200px) {
        background-size: cover;
        width: 100%;
        min-width: 700px;
        // background-size: 1000px 609px;
        // background-size: 850px 500px;
    }
`;

const Mockup = (props) => {
    console.log(props.title);
    return(
        <div className="mockup-wrapper">
            {/* <MockupImage title={props.title} widthPlantacle={props.widthPlantacle} widthBanzai={props.widthBanzai} heightPlantacle={props.heightPlantacle} heightBanzai={props.heightBanzai}> */}
            <MockupImage title={props.title}>
            </MockupImage>
        </div>
    )
};

export default Mockup;

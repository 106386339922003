import React from 'react';
import PageNumber from './../../../components/atoms/PageNumber';
import Mockup from './../../../components/atoms/Mockup';
import ProjectText from './../../../components/molecules/ProjectText';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

const ProjectSection = styled.section`
    // width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 500px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {
        position: relative;
        flex-direction: row;
    }

    @media (min-width: 1200px) {
    }
`;

const Project = (props) => {
    return(
        <Container style={{ maxWidth: '2000px' }}>
            <ProjectSection
            className="project"
            id="section3"
            title={props.title}
            //   topXxs={props.topXxs}
            //   topXs={props.topXs}
            //   topSm={props.topSm}
            //   topMd={props.topMd}
            //   topLg={props.topLg}
            >
                <div className="project__pagenumber--wrapper">
                    <PageNumber title={props.title} count={props.count}> </PageNumber>
                </div>
                <h2 className="project__title"> {props.title} </h2>

                {/* <Mockup title={props.title} widthPlantacle={props.widthPlantacle} widthBanzai={props.widthBanzai} heightPlantacle={props.heightPlantacle} heightBanzai={props.heightBanzai}> </Mockup> */}
                <Mockup title={props.title}> </Mockup>

                <div className="project__right-block">
                    <ProjectText title={props.title} text={props.text} buttonLink={props.buttonLink} labels={props.labels}> </ProjectText>
                </div>
            </ProjectSection>
        </Container>
    )
};

export default Project;

import React from 'react';
import { Container } from 'react-bootstrap';
// components
import Intro from './sections/Intro';
import Role from './sections/Role';
import SoundCloudBlownAway from './sections/BlownAway/SoundCloudBlownAway';
import YouTubeBlownAway from './sections/BlownAway/YoutubeBlownAway';

class DetailPhaseRunner extends React.Component {
  render() {
    return (
      <Container fluid>
          <Intro
           title="Phase Runner"
           text="In the year 2274 the Space Explorer, a ship under your command, has been infiltrated by enemy aliens during an expedition. While escaping you find out you are not the only one they’re after. Escape through portals and don't get caught by the enemies, no matter the cost."
           countTitle="phase runner"
           count="0.4.1"
           id="phaserunner1"
           >
          </Intro>
          {/* <div className="phase-runner-google-play-wrapper">
          <a href='https://play.google.com/store/apps/details?id=com.NurdleStudio.PhaseRunnersMultiplayer&hl=en_US&gl=US&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' className="phaserunner-google-play" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
          </div> */}

          <Role
            role="Composer, Sound designer"
            responsibility="My responsibility was immersing the player into the game through music and sound design. Every time the character goes through a new wormhole they enter a new dimension. There are four dimensions in total which is why I produced four different compositions. Each composition pairs well with the affiliated dimension."
            build="Ableton and Synthesizers"
            buildTitle="Tools"
            project="Phase Runner"
          />
            <SoundCloudBlownAway
             title={ "Music"}
              text={"Whenever the player enters a portal, they are transported to a new dimension. Writing music for each respective dimension was the project's biggest challenge. Extensive research into different styles was necessary to find suitable options for each dimension, ultimately resulting in tracks that effectively represent their respective environments."}
              url={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1540836571&color=%239200ff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"}
              className={"blownaway-soundcloud"}
              count={"0.2.2"}
            />
            <SoundCloudBlownAway
              title={"Sound design"}
              text={""}
              url={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1540842229&color=%239200ff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"}
              className={"blownaway-soundcloud2"}
              count={"0.2.3"}
            />

        <YouTubeBlownAway
          title={"Phase Runner trailer"}
          url={"https://www.youtube.com/embed/0EUBSbkgTKo"}
          text={""}
          />
      </Container>
    )
  }
}

export default DetailPhaseRunner;

import React from 'react';
import { Container } from 'react-bootstrap';
import PageNumber from './../../../components/atoms/PageNumber';
import SvgBackbutton from './../../../components/svg/Backbutton';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';

import imgDetailPlantacle from './../../../images/tropicana.jpg';
import imgBlownAway from './../../../images/wokkel-image.png';
import imgPhaseRunner from './../../../images/phase-runner-image.png';
import imgStrakzat from './../../../images/strakzat.png';
import imgDetailILE from './../../../images/erasmus.png';
import imgMusicTerra from './../../../images/musicterr.png';


const DetailImg = styled.div`
    background-image: url(${props => props.title === 'Plantacle' ? imgDetailPlantacle : props.title === 'Blown Away' ? imgBlownAway : props.title === 'Phase Runner' ? imgPhaseRunner : props.title === 'Strakzat' ? imgStrakzat : props.title === 'Music Terra' ? imgMusicTerra : null});
    background-size: ${(props) => props.title === 'Phase Runner' ? '240px 250px' : props.title === 'Plantacle' ? '240px 120px' : props.title === 'Strakzat' ? '250px 120px' : '260px 180px'};
    background-repeat: no-repeat;
    width: 251px;
    height: 180px;
    margin: 0 auto;
    //background-color:grey;
    background-position: center;

    @media (min-width: 500px) {
        background-size: ${(props) => props.title === 'Blown Away' ? '351px 240px' : props.title === 'Phase Runner' ? '320px 400px' : '351px 180px'};
        background-repeat: no-repeat;
        width: 351px;
        height: 180px;
        background-position: center;

    }

    @media (min-width: 768px) {
        background-size: ${(props) => props.title === 'Blown Away' ? '550px 350px' : props.title === 'Phase Runner' ? '420px 500px' : '550px 250px'};
        background-repeat: no-repeat;
        width: 550px;
        height: 250px;
    }

    @media (min-width: 1024px) {
        background-size: ${(props) => props.title === 'Blown Away' ? '500px 280px' : props.title === 'Phase Runner' ? '500px 580px' : '500px 250px'};
        background-repeat: no-repeat;
        width: 500px;
        height: ${(props) => props.title === 'Phase Runner' ? '550px' : '250px'};

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media (min-width: 1200px) {
        background-size: ${(props) => props.title === 'Blown Away' ? '600px 360px' : props.title === 'Phase Runner' ? '550px 620px' : '600px 310px'};
        background-repeat: no-repeat;
        width: 600px;
        height: 310px;
        left: 50%;
        top:  ${(props) => props.title === 'Phase Runner' ? '40%' : '55%'};
    }
`;

class Intro extends React.Component {
  render(props) {
    return (
        <section className="intro-detail" id={this.props.id}>
                <Link to="/#section1" className="intro-detail__svg-wrapper">
                    <SvgBackbutton> </SvgBackbutton>
                </Link>
                <div className="intro-detail__pagenumber-wrapper">
                    <PageNumber title={this.props.countTitle} count={this.props.count}> </PageNumber>
                </div>
            <h2 className="intro-detail__title--mobile"> {this.props.title} </h2>

            <div className="intro-detail__img-wrapper">
                <DetailImg title={this.props.title}/>
            </div>

            <div className="intro-detail__text-block">
                <div className="intro-detail__text-wrapper">
                    <h2 className="intro-detail__title--desktop"> {this.props.title} </h2>
                    <p className="intro-detail__text">
                      {this.props.text}
                    </p>

                { this.props.githubLink ?
                <a href={this.props.githubLink} className="intro-detail__link">{this.props.linkText}</a> : null
                }
                </div>
            </div>
        </section>
    )
  }
}

export default Intro;

import React from 'react';
import { Container } from 'react-bootstrap';
import SoundCloudBlownAway from './sections/BlownAway/SoundCloudBlownAway';
import YouTubeBlownAway from './sections/BlownAway/YoutubeBlownAway';

// components
import Intro from './sections/Intro';
import Role from './sections/Role';


class DetailDivineApocalypse extends React.Component {
  render() {
    return (
      <Container fluid>
          <Intro
            title="Divine Apocalypse"
            text="The Divine Apocalypse soundtrack is made for Kallista's cover art. During creation I imagined a heroic female super hero team fighting against gods and injustice. The intro was created with the thought of their arrival into the realm of the gods. I used strings and heavy percussion to highlight the difficuly of the coming battle. Slowly it builds up slowly until it reaches conflict. The brass instruments indicate the transition from encounter into conflict. During conflict I wanted to set the notion that the heroes are going to win. The chords and melodies switch to more triumphant chords and melodies which eventually lead to the climax. For the climax I combined the previously used instruments with a newly added choir. Now with the new combination of these instruments I visualized the heroes dishing out the final blows against the gods and coming out victorious. "
            countTitle="b.a"
            count="0.3.1"
            id="blownaway1"
          >
          </Intro>
          <Role
            role="Composer, Sound designer"
            responsibility="Composing a soundtrack using orchestral instruments"
            build="Ableton, Orchestral VST's"
            buildTitle="Tools"
            project="Divine Apocalypse"
          />
            <SoundCloudBlownAway
             title={ "Music"}
              text={""}
              url={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1579026250&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"}
              className={"blownaway-soundcloud"}
            />
      </Container>
    )
  }
}

export default DetailDivineApocalypse;

import React from 'react';

import styled from 'styled-components';

const SectionRole = styled.section`
  width: 100%;

  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
      justify-content: flex-start;
  }

  @media (min-width: 500px) {
  }


  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {



  @media (min-width: 1200px) {

  }

`

class Role extends React.Component {
  render(props) {
    return (
        <SectionRole project={this.props.project}>
          <div className="role__text-wrapper">
              <div class="intro-detail__wave"> </div>
              <div className="role__text">
                  <p className="intro-detail__info"> <b> Role: </b> {this.props.role}</p>
                  <p className="intro-detail__info"> <b> Responsibility: </b> {this.props.responsibility}</p>
                  <p className="intro-detail__info"> <b> { this.props.buildTitle }: </b> {this.props.build}</p>
              </div>
          </div>
        </SectionRole>

    )
  }
}

export default Role;

import React from 'react';

const Label = (props) => {
    return(
        <span className="project__label">
            { props.labelText }
        </span>
    )
};

export default Label;

import React from 'react';
import SvgButton from './../svg/Button';
import { HashLink as Link } from 'react-router-hash-link';

const Button = (props) => {
      // Check if the title is "Bloody Hell II" to decide which element to render
      const isBloodyHellII = props.title === 'Bloody Hell II';
    return(
        <span>
        <SvgButton className="project__button" disabled={isBloodyHellII}>
        </SvgButton>
        {isBloodyHellII ? (
            <span 
                className="project__button-text project__button-text-centered"
                style={{ cursor: 'none', pointerEvents: 'none' }}  // Disables link behavior and cursor
            >
                TBA
            </span>
        ) : (
            <Link 
                to={props.buttonLink} 
                className="project__button-text"
            >
                Read more!
            </Link>
        )}
    </span>
    )
};

export default Button;

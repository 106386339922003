import React from 'react';
import { Container } from 'react-bootstrap';
import PageNumber from '../../../../components/atoms/PageNumber';
import styled from 'styled-components';

const Image = styled.div`

    background-repeat: no-repeat;
    background-size: 100% 100%;

    @media (max-width: 500px) {

    }

    @media (min-width: 500px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {

    }

    @media (min-width: 1200px) {

    }
`;

const ContentMusicTerra = (props) => {
    console.log(props.url);
    return (
        <section className="content-music-terra">
            {/* <div className="content-music-terra__pagenumber-wrapper">
                <PageNumber title="musicterra" count={props.count} className="content-music-terra__pagenumber"> </PageNumber>
            </div> */}

            <div className="content-music-terra__title-wrapper">
                <h2 className="content-music-terra__title"> {props.title } </h2>
            </div>
            { props.image ?

            <div className="content-music-terra__image-wrapper">
                {/* <Image url={props.urlName}>
                </Image> */}
                <img src={props.urlName} className="content-music-terra__image" />
            </div>
            : null

            }

            <div className="content-music-terra__text-wrapper">
                <p className="content-music-terra__text"> {props.text} </p>
            </div>
        </section>
    )
}

export default ContentMusicTerra;

import React from 'react';
import { Container } from 'react-bootstrap';
import PageNumber from '../../../../components/atoms/PageNumber';


// components


class SoundCloudBlownAway extends React.Component {
  render() {
    return (
        <section className={this.props.className}>
            <div className="blownaway-soundcloud__title-wrapper">
              <h2 className="blownaway-soundcloud__title"> { this.props.title } </h2>
            </div>

            <div className="blownaway-soundcloud__playlist">
            <iframe
            className="blownaway-soundcloud__iframe"
            width="50%"
            height="500"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src={ this.props.url }>
            </iframe>
            <div className="blownaway-soundcloud__text-wrapper">
              <p className="blownaway-soundcloud__text"> { this.props.text } </p>
            </div>
            </div>

        </section>
    )
  }
}

export default SoundCloudBlownAway;

import React from 'react';
import { Container } from 'react-bootstrap';
import SoundCloudBlownAway from './sections/BlownAway/SoundCloudBlownAway';
import YouTubeBlownAway from './sections/BlownAway/YoutubeBlownAway';

// components
import Intro from './sections/Intro';
import Role from './sections/Role';


class DetailBlownAway extends React.Component {
  render() {
    return (
      <Container fluid>
          <Intro
            title="Keys of Skynerium"
            text="Our hero, Gracian, is the new gatekeeper of all the gates from which monsters emerge. Normally, all the gates remain closed. However, due to a catastrophic event that occurred thousands of years ago, the gates opened, releasing the monsters
            within to wreak havoc upon the world."
            countTitle="b.a"
            count="0.3.1"
            id="skynerium1"
          >
          </Intro>
          <Role
            role="Composer, Audio Implementer (Wwise), Programmer"
            responsibility="
            Created and implemented the soundtracks using Wwise. Additionally, wrote a script to trigger Wwise events for each scene in game."
            build="Logic Pro, Orchestral VST's, Wwise"
            buildTitle="Tools"
            project="Skynerium"
          />
            <SoundCloudBlownAway
             title={"Music"}
              text={""}
              url={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1839282933&color=%234a6654&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"}
              className={"blownaway-soundcloud"}

            />
      </Container>
    )
  }
}

export default DetailBlownAway;

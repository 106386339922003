import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SvgArrowDown from '../../components/svg/ArrowDown';


// components

import Start from './sections/Start';
import Intro from './sections/Intro';
import Project from './sections/Project';


class Home extends React.Component {
  render() {
    return (
      <Container fluid>
          <Start> </Start>
          <Intro></Intro>
              <Project
              title="Bloody Hell II"
              text="Embark on a thrilling quest in a frozen hell as a white bird. Gather pieces of God's essence to restore balance, but beware of deception."
              count="01"
              buttonLink="/phase-runner/#phaserunner1"
              // widthBanzai="708px"
              // heightBanzai="697px"
              // topXxs="3750px"
              // topXs="3800px"
              // topSm="4200px"
              // topMd="1300px"
              // topLg="1400px"
              labels={['composer', 'audio implementer', 'TBA']}
              >
              </Project>
              <Project
                title="Divine Apocalypse"
                text="This soundtrack was created for the female superhero cover art made by the illustrator Kaliska."
                count="02"
                buttonLink="/divine-apocalypse/#divineapocalypse1"
                // widthBanzai="708px"
                // heightBanzai="697px"
                // topXxs="1700px"
                // topXs="1800px"
                // topSm="2050px"
                // topMd="1300px"
                // topLg="1400px"
                labels={['composer']}
              >
              </Project>

              <Project
                title="Blown Away"
                text="Blown away is a singleplayer explorer game filled with puzzles, obstacles and hidden locations. The game takes place in a civilization called Yhorba influenced by bubbles."
                count="03"
                buttonLink="/blown-away/#blownaway1"
                // widthBanzai="708px"
                // heightBanzai="697px"
                // topXxs="2700px"
                // topXs="2780px"
                // topSm="3100px"
                // topMd="1300px"
                // topLg="1400px"
                labels={['composer', 'sound designer']}
                >
                </Project>

                {/* <Project
                title="Music Terra"
                text="Music Terra is a post-apocalyptic virtual world where players can connect and play music. Create rooms where you and your fellow musicians can participate."
                count="02"
                buttonLink="/music-terra/#musicterra1"
                tag="music-terra"
                // widthBanzai="708px"
                // heightBanzai="697px"
                // topXxs="1700px"
                // topXs="1800px"
                // topSm="2050px"
                // topMd="1300px"
                // topLg="1400px"
                labels={['development', '3D modeling', 'UI design', 'texturing']}
              >
              </Project> */}

              <Project
                title="Skynerium"
                text="
                Embark on an epic adventure in Skynerium as a gatekeeper tasked with finding key pieces to seal away destructive monsters. Battle foes, solve puzzles, and restore peace."
                count="04"
                buttonLink="/skynerium/#skynerium1"
                // widthBanzai="708px"
                // heightBanzai="697px"
                // topXxs="3750px"
                // topXs="3800px"
                // topSm="4200px"
                // topMd="1300px"
                // topLg="1400px"
                labels={['composer', 'audio implementer', 'programmer']}
                >
              </Project>



              <Project
              title="Phase Runner"
              text="Phase Runner is an endless runner mobile game which was created during a game jam. Your ship, Space Explorer, has been infiltrated by enemy aliens to hijack important data. Their target for this mission: you. Escape through the portals and make sure you don’t get captured."
              count="05"
              buttonLink="/phase-runner/#phaserunner1"
              // widthBanzai="708px"
              // heightBanzai="697px"
              // topXxs="3750px"
              // topXs="3800px"
              // topSm="4200px"
              // topMd="1300px"
              // topLg="1400px"
              labels={['game jam', 'composer', 'sound designer']}
              >
              </Project>


              {/* <Project
                title="Plantacle"
                text="
                Plantacle is an application that helps reduce greenhouse gas emissions that affect climate change. A sensor sends its measurements to the application, keeping the user up to date with the composting bin’s process and needs. "
                count="06"
                buttonLink="/plantacle/#plantacle1"
                // widthBanzai="708px"
                // heightBanzai="697px"
                // topXxs="4900px"
                // topXs="4850px"
                // topSm="5350px"
                // topMd="1300px"
                // topLg="1400px"
                labels={['development', 'concepting', '2D design', '3D design']}
              >
              </Project>

              <Project
                title="Strakzat"
                text="Built the Strakzat and Objectiv website during and after my internship."
                count="07"
                buttonLink="/strakzat/#strakzat1"
                // topXxs="5950px"
                // topXs="5850px"
                // topSm="6500px"
                // topMd="1300px"
                // topLg="1400px"
                labels={['development']}
              >
              </Project> */}

      </Container>
    )
  }
}

export default Home;

import React from 'react';
import { Container } from 'react-bootstrap';
import PageNumber from '../../../../components/atoms/PageNumber';


// components


class YouTubeBlownAway extends React.Component {
  render() {
    return (
        <section className="youtube-blown-away">
            <div className="youtube-blown-away__title-wrapper">
              <h2 className="youtube-blown-away__title"> { this.props.title } </h2>
            </div>

            <div className="youtube-blown-away__wrapper">

            <iframe className="youtube-blown-away__iframe" width="660" height="415" src={this.props.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <div className="youtube-blown-away__text-wrapper">
              <p className="youtube-blown-away__text"> { this.props.text } </p>
            </div>
            </div>
        </section>
    )
  }
}

export default YouTubeBlownAway;

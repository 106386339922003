import React from 'react';
import { Container } from 'react-bootstrap';
// components
import Intro from './sections/Intro';
import ContentMusicTerra from './sections/MusicTerra/ContentMusicTerra';
import Role from './sections/Role';
import lobby from '../../images/MusicLobby.png';
import musicRoom from '../../images/MusicRoom.png';
import musicUI from '../../images/Music-lobby-screen.jpg';
import character from '../../images/character.png';


// Img urls for content music terra

class DetailMusicTerra extends React.Component {
  render() {
    return (
      <Container fluid>
          <Intro
           title="Music Terra"
           text="MusicTerra is a world where people try to reconnect and rebuild after the planet had been hit by a catastrophic event. There are two requirements to be able to participate in MusicTerra: you can only be there using your online avatar and digital instrument. Utilising your avatar makes it possible to enter the Music Lobby which serves as a gateway between MusicTerra and the physical world. The Music Room is used for making music with your fellow musicians. Connect your digital instrument using the MIDI input or an audio interface and immerse yourself in the virtual music session."
           countTitle="music terra"
           count="0.2.1"
           githubLink="https://github.com/HayenPhan/MusicTerra"
           linkText="View this project on Github"
           id="musicterra1"
           >
          </Intro>
          <Role
            role="Unity developer, 3D modeler and UI designer"
            responsibility="What I did:
            3D Character design in Blender
            - Character animation in Blender
            - Clothing design in Blender
            - Texturing in Substance Painter
            - Designing rooms using the Probuilder tool in Unity
            - Light baking in Unity
            - Modelling grass and flowers in Blender
            - Creating grass and flower shaders using Unity Shader Graph
            - Programming character movement using C#
            - Programming multiplayer functionality using C#"
            buildTitle="Tools"
            build="Unity, ProBuilder, Blender, Substance Painter, Illustrator and Photoshop"
            project="Music Terra"
          />
          <ContentMusicTerra
            title={"Lobby"}
            text={"I decided to go for an immersive gameplay using different styles for the environment. I wanted to create a melancholic space and did a lot of research on the different variations of stylistic identity. I went for a cyberpunk/post-apocalyptic/dystopian style, to give the player the feeling that the lobby was once a peaceful place for leisure and making music before the apocalyptic event took place."}
            urlName={lobby}
            image={true}
          />

          <ContentMusicTerra
            title={"Music Room"}
            text={"The music room has been equipped with a planetarium and a marble stage surrounded by grass and flowers. All this is wrapped in a starry night sky. The sharp yet linear contrast between the lobby design and music room design was curated in such a way to symbolise the hope that music can represent. At first I tried to design the planetarium with the ProBuilder tools in Unity, but I stumbled upon a problem with unexpected lines that I couldn’t remove. I tried to ask for help in the Unity discord server, but to no avail. So I decided to make the planetarium model in Blender, imported it into Unity, and therefore achieved the result I had in mind. The galaxy material in the planetarium was made using a galaxy picture as the base with emission in the material. The starry night sky wall, grass and flowers were created using the Unity Graph Shader and Blender. The grass and flower objects are basically a bunch of planes with shaders."}
            image={true}
            urlName={musicRoom}
          />

          <ContentMusicTerra
            title={"UI Elements"}
            text={"The biggest UI elements that I created were the room selection UI. Every player in Music Terra can create different types af rooms, such as solo, duo, trio and ensemble. It’s also possible to select or create a room based on genre. Before designing the UI in Illustrator and Photoshop, I did some research on the different UI options: Diegetic, Non-Diegetic, Spatial and Meta UI. I decided to implement the most suitable UI options. The room selection UI is a Diegetic UI element; while the Spatial UI elements are the profile banner in the left corner and the buttons in the right corner of the screen."}
            image={true}
            urlName={musicUI}
          />

          <ContentMusicTerra
            title={"Development"}
            text={"I’ve programmed the movements of the character, added Cinemachine Virtual Camera and wrote a script  to focus on the localPlayer. The Photon Fusion Network library was used to add online multiplayer to the game. "}
            image={false}
          />

        <ContentMusicTerra
            title={"Character Design"}
            text={"I used front en side sketches to create the character’s head and body. To work more efficiently, I used modifiers and various add-ons. The part that was hardest to do as accurately as possible were the hips and the mouth area. I realised that I still had a lot to learn about the human anatomy."}
            image={true}
            urlName={character}
          />
      </Container>
    )
  }
}

export default DetailMusicTerra;

import * as React from "react";

function SvgArrowDown(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 60 55"{...props}>
      <path
        data-name="Path 5"
        d="M10.851.587Q9.46 18.172 8.122 35.763a124.334 124.334 0 00-1.759-3.2c-.213-.426-.418-.857-.628-1.286a.722.722 0 00-.08-.677 24.47 24.47 0 01-1.221-2.033 157.824 157.824 0 01-1.4-3.073.749.749 0 00-1.2-.257c-.165-.246-.318-.5-.485-.74-.509-.746-1.571-.01-1.3.764.022.064.047.127.069.191a.8.8 0 00.052.336c.3.8.608 1.588.925 2.375a120.954 120.954 0 004.808 11.208.745.745 0 00.729.437q.511.928 1.041 1.849l-.073.939a.675.675 0 00.725.767l-.012.211c-.033.571.847.685.888.108l.1-1.378.3-.58a.719.719 0 00.072-.095c.421-.813.819-1.635 1.226-2.454.188-.379.382-.755.567-1.136q.963-1.976 1.867-3.977c.082-.18.168-.358.249-.539q2.106-4.685 3.919-9.5a.736.736 0 00-1.343-.593.7.7 0 00-.983.371c-1.69 3.681-3.885 7.5-5.367 11.459Q11.042 18.008 12.233.755c.047-.884-1.312-1.057-1.382-.168zM5.099 33.564q-.106-.252-.213-.5c.085.149.165.3.25.449q1.33 2.642 2.756 5.236l-.021.278Q6.4 36.332 5.098 33.564z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgArrowDown;
